import { toast } from "react-toastify";

const Toastify = (info) => {
    const {message, state} = info
    switch (state) {
        case "success":
            toast.success(message, {autoClose: 8000});
            break;
        case "error":
            toast.error(message, {autoClose: 8000});
            break;
        case "warning":
            toast.warning(message, {autoClose: 8000});
            break;
        default:
            toast.info(message, {autoClose: 8000});
            break;
    }

}

export default Toastify