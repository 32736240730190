import { useContext } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import NavigationTabStyle from './NavigationTab.module';
import AppContext from '../../contexts/app-context';
import {useTranslation} from 'react-i18next'
import {app_paths} from '../config/variables'

const NavigationTab = () => {
  const classes = NavigationTabStyle();
  const location = useLocation();
  const new_patient = location.pathname === app_paths.NEW_PATIENT;
  const appCtx = useContext(AppContext)
  const direction = appCtx.direction
  const {t} = useTranslation('global')
  return (
    <Box>
      <Box sx={classes.container} dir={direction}>
        {/* <NavLink to={app_paths.MACHINE_LEARNING} style={classes.nav_link_style({ isActive: !new_patient })}>
          <TextSnippetIcon sx={classes.icons} />
          <Typography sx={classes.tab_title}>
            {t('MedicalTextAnalysis')}
          </Typography>
        </NavLink> */}
        <NavLink to={app_paths.NEW_PATIENT} style={classes.nav_link_style({ isActive: new_patient })}>
          <AddCircleOutlineIcon sx={classes.icons} />
          <Typography sx={classes.tab_title}>
            {t('NewPatient')}
          </Typography>
        </NavLink>
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  )
}

export default NavigationTab