import { useState, useEffect, useRef, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import classes from './Chatbot.module.css';
import AuthContext from '../../../contexts/auth-context';
import SendIcon from '@mui/icons-material/Send';
import { urls, chatbotCase } from '../../config/variables';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const Chatbot = () => {
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const bottomRef = useRef(null);
  const authCtx = useContext(AuthContext);
  const userProfileName = authCtx.userProfile.user.name
  const gender = authCtx.userProfile.user.gender;
  const [messages, setMessages] = useState([
    { text: `Hello ${userProfileName}! How can I assist you today?`, sender: 'bot' }
  ]);

  const handleToggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSend = async () => {
    if(!loading && input.length > 0) {
        const userMessage = { text: input, sender: 'user' };
        setMessages([...messages, userMessage]);
        setInput('')

        const botResponse = await getBotResponse(userMessage.text);
        setLoading(false)
        setMessages(prevMessages => [
          ...prevMessages,
          { text: botResponse, sender: 'bot' }
        ]);
    }
  };

  const getBotResponse = async (userInput) => {
    if (userInput.toLowerCase().includes('hello')) {
      return 'Hi there! How can I assist you?';
    }

    const url = urls.CHATBOT_AI_SERVER;
    const data = { query: userInput };

    try {
      setLoading(true)
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data.answer

    } catch (error) {
      setLoading(false)
      console.error(error.response ? error.response.data : error.message);
      return 'Something went wrong!'
    }
  };

  const handleChange = (e) => {
    if(!loading) {
      setInput(e.target.value)
    }
  }

  const handleKeyDown = (e) => {
    if( e.key === 'Enter') {
      e.preventDefault();
      handleSend()
    }
  }

  return (
    <>
      <div
        className={`${classes.card_container} ${isOpen && classes.card_container_open}`}
      >
        <div className={classes.card_header}>
          <img
            className={classes.header_avatar}
            src="/images/chatbot.png"
            alt="Chatbot"
          />
          <h1 className={classes.title}>Veritas Pulse Assistant</h1>
          <CloseIcon
            className={classes.close_icon}
            onClick={handleToggle}
          />
        </div>

        <div className={classes.chat_container}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`${classes.message_box} ${
                message.sender === 'bot'
                  ? classes.bot_message_box
                  : classes.user_message_box
              }`}
            >
              <img
                src={
                  message.sender === "user"
                    ? gender === "F"
                      ? "/images/patient_woman.svg"
                      : "/images/patient_man.svg"
                    : "/images/chatbot.png"
                }
                alt={message.sender === "user" ? "User" : "Chatbot"}
                className={`${classes.message_avatar} ${
                  message.sender === 'bot'
                    ? classes.bot_message_avatar
                    : classes.user_message_avatar
                }`}
              />
              <p
                className={`${classes.message} ${
                  message.sender === 'bot'
                    ? classes.bot_message
                    : classes.user_message
                }`}
              >
                {message.text}
              </p>
            </div>
          ))}
          {loading && (
            <div className={classes.loading_message_box}>
              <img
                src="/images/chatbot.png"
                alt="Chatbot"
                className={classes.loading_message_avatar}
              />
              <CircularProgress size={25} />
            </div>
          )}
          <div ref={bottomRef} className={classes.bottom_ref} />
        </div>

        <div className={classes.bottom}>
          <div className={classes.input_box}>
            <textarea
              className={classes.input}
              placeholder="Ask something."
              value={input}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            <SendIcon
              className={classes.submit}
              onClick={handleSend}
            />
          </div>
        </div>
      </div>

      {!isOpen && (
        <div
          className={`${classes.bot_avatar_box} ${
            !chatbotCase && classes.bot_avatar_box_hide
          }`}
        >
          <img
            className={classes.bot_avatar}
            src="/images/chatbot.png"
            alt="Chatbot"
            onClick={handleToggle}
          />
        </div>
      )}
    </>
  );
};

export default Chatbot;