import { useTheme } from '@emotion/react';
import { inputLabelClasses } from "@mui/material/InputLabel";

const AuthFormStyle = () => {
    const theme = useTheme();
    return {
        auth_container: {
            padding: { md: '15px', lg: '15px' },
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto',
        },
        auth_form_h1: {
            fontSize: '32px',
            textAlign: 'center'
        },
        auth_form_h2: {
            fontSize: '16px',
            textAlign: 'center',
            color: theme.palette.custom.black['70']
        },
        form_container: {
            marginTop: '50px',
            width: '100%',
        },
        alert_box: {
            direction: 'ltr'
        },
        input_style: {
            marginTop: '32px',
            width: '100%'
        },
        label_style: {
            color: theme.palette.custom.black['70'],
            [`&.${inputLabelClasses.shrink}`]: {
                color: theme.palette.custom.black['80']
            }
        },
        submit_button: {
            padding: '12px 0',
            marginTop: '64px',
        },
        contact_us: {
            marginTop: '16px',
            textAlign: 'center',
            fontSize: '16px',
            textTransform: 'capitalize',
            color: theme.palette.custom.black['80']
        },
        contact_us_nav: {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
        title: {
            margin: '10px 0',
            fontSize: '20px',
            fontWeight: '500',
            borderBottom: '2px solid #4ABBA7',
            display: 'inline',
            paddingBottom: '10px'
        }
    };
}

export default AuthFormStyle;
